<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Formulario {{ accion }} Operación</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">{{ accion }} Operación</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Principal"
                      data-toggle="tab"
                      href="#Principal"
                      >Principal</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      v-if="operacion"
                      id="tab-Productos"
                      data-toggle="tab"
                      href="#Productos"
                      >Productos Operación
                    </a>
                  </li>
                  <li
                    class="nav-item"
                    v-if="form.descripcion == 4 && operacion"
                  >
                    <a class="nav-link" id="tab-Km" data-toggle="tab" href="#Km"
                      >Asignación Kilometraje
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <!-- Panel Principal-->
                  <div class="tab-pane fade active show" id="Principal">
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-4">
                          <label>Bloque:</label>
                          <v-select
                            :class="[
                              $v.form.bloque.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.bloque"
                            placeholder="Bloque"
                            label="nombre"
                            :options="listasForms.bloques"
                            :filterable="false"
                            @search="buscarBloques"
                            class="form-control form-control-sm p-0"
                            :disabled="
                              !$store.getters.can('tif.operaciones.edit') ||
                                act == 2 ||
                                accion == 'Editar'
                            "
                          ></v-select>
                          <div class="error" v-if="!$v.form.bloque.required">
                            Seleccione un bloque
                          </div>
                        </div>
                        <div class="form-group col-md-4">
                          <label for="descripcion">Nombre</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.descripcion"
                            :class="
                              $v.form.descripcion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              !$store.getters.can('tif.operaciones.edit') ||
                                act == 2 ||
                                accion == 'Editar'
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="operacion in listasForms.operaciones"
                              :key="operacion.numeracion"
                              :value="operacion.numeracion"
                            >
                              {{ operacion.descripcion }}
                            </option>
                          </select>
                          <div
                            class="error"
                            v-if="!$v.form.descripcion.required"
                          >
                            Ingrese un Nombre
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--- Panel Productos Operaciones --->
                  <div class="tab-pane fade" id="Productos" v-if="operacion">
                    <div class="card-body pt-0">
                      <div class="row">
                        <div class="col-md-12 p-2">
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="operacion_sitio">Productos</label>
                                <select
                                  class="form-control form-control-sm"
                                  v-model="producto"
                                  :disabled="
                                    !$store.getters.can(
                                      'tif.operaciones.edit'
                                    ) || act == 2
                                  "
                                >
                                  <option value="">Seleccione...</option>
                                  <option
                                    v-for="p in listasForms.productos"
                                    :key="p.id"
                                    :value="p.id"
                                  >
                                    {{ p.nombre }}
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-3">
                              <button
                                type="button"
                                class="btn btn-primary"
                                style="margin-top: 25px"
                                v-show="producto"
                                @click="validarOperacion()"
                                v-if="
                                  $store.getters.can(
                                    'tif.operaciones.saveProducto'
                                  )
                                "
                                :disabled="
                                  !$store.getters.can('tif.operaciones.edit') ||
                                    act == 2
                                "
                              >
                                <i class="fas fa-paper-plane"></i> Guardar
                                Producto
                              </button>
                            </div>
                          </div>

                          <!-- TABLA OPERACION -->
                          <div
                            class="card-body p-0"
                            v-if="Object.keys(listasForms.pho).length > 0"
                          >
                            <table
                              class="
                                  table table-bordered table-striped table-hover table-sm
                                "
                            >
                              <thead class="thead-dark">
                                <tr>
                                  <th class="text-center">Producto</th>
                                  <th class="text-center" style="width: 1%">
                                    Accion
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="item in listasForms.pho.productos"
                                  :key="item.id"
                                >
                                  <td style="width: 10px;">
                                    {{ item.nombre }}
                                  </td>
                                  <td class="text-center">
                                    <div class="btn-group float-left">
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        v-on:click="destroy(item.pivot)"
                                        v-if="
                                          $store.getters.can(
                                            'tif.operaciones.deleteProducto'
                                          )
                                        "
                                        :disabled="
                                          !$store.getters.can(
                                            'tif.operaciones.edit'
                                          ) || act == 2
                                        "
                                      >
                                        <i class="fas fa-trash-alt"></i>
                                      </button>
                                      <button
                                        data-toggle="modal"
                                        data-target="#modal-form-cargos"
                                        type="button"
                                        class="btn btn-sm btn-success"
                                        @click="abrirModal(item.pivot)"
                                        v-if="
                                          $store.getters.can(
                                            'tif.operaciones.showCargo'
                                          )
                                        "
                                        :disabled="
                                          !$store.getters.can(
                                            'tif.operaciones.edit'
                                          ) || act == 2
                                        "
                                      >
                                        <i class="fas fa-user-plus"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--- Asignación Km Operaciones --->
                  <div
                    class="tab-pane fade"
                    id="Km"
                    v-if="operacion && form.descripcion == 4"
                  >
                    <div class="card-body pt-0">
                      <div class="row">
                        <div class="col-md-12">
                          <div
                            class="position-relative p-3 m-2 bg-light"
                            style="font-size: 0.85em"
                          >
                            <div class="ribbon-wrapper">
                              <div class="ribbon bg-info">Km</div>
                            </div>
                          </div>
                          <form>
                            <div class="row">
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label for="tif_origen_id"
                                    >Sitio Origen</label
                                  >
                                  <v-select
                                    :class="[
                                      $v.formKm.tif_origen_id.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid',
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : '',
                                    ]"
                                    v-model="origen"
                                    placeholder="Origen"
                                    label="nombre"
                                    :options="listasForms.tif_origen"
                                    @input="getSelectOrigen()"
                                    class="form-control form-control-sm p-0"
                                    :disabled="
                                      !$store.getters.can(
                                        'tif.operaciones.edit'
                                      ) || act == 2
                                    "
                                  ></v-select>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label for="tif_destino_id"
                                    >Sitio Destino</label
                                  >
                                  <v-select
                                    :class="[
                                      $v.formKm.tif_destino_id.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid',
                                      $store.getters.getDarkMode
                                        ? 'dark-vselect'
                                        : '',
                                    ]"
                                    v-model="destino"
                                    placeholder="Destino"
                                    label="nombre"
                                    :options="listasForms.tif_destino"
                                    @input="getSelectDestino()"
                                    class="form-control form-control-sm p-0"
                                    :disabled="
                                      !$store.getters.can(
                                        'tif.operaciones.edit'
                                      ) || act == 2
                                    "
                                  ></v-select>
                                </div>
                              </div>
                              <div class="col-md-1">
                                <div class="form-group">
                                  <label for="kilometraje">Kilometraje</label>
                                  <input
                                    type="number"
                                    class="form-control form-control-sm"
                                    v-model="formKm.kilometraje"
                                    :class="
                                      $v.formKm.kilometraje.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                    :disabled="
                                      !$store.getters.can(
                                        'tif.operaciones.edit'
                                      ) || act == 2
                                    "
                                  />
                                </div>
                              </div>

                              <div class="col-md-2">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  style="margin-top: 25px"
                                  v-show="!$v.formKm.$invalid"
                                  @click="validarKm()"
                                  v-if="
                                    $store.getters.can('tif.operaciones.saveKm')
                                  "
                                >
                                  <i class="fas fa-paper-plane"></i> Guardar Km
                                </button>
                              </div>
                            </div>
                          </form>

                          <div>
                            <table class="table">
                              <thead class="thead-dark">
                                <tr>
                                  <th>Sitio Origen</th>
                                  <th>Sitio Destino</th>
                                  <th>Bloque</th>
                                  <th>Kilometraje</th>
                                  <th>Acción</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(km, index) in kilometros"
                                  :key="km.id"
                                >
                                  <td>
                                    <span
                                      v-if="
                                        formActualizarKm &&
                                          idActualizarKm == index
                                      "
                                    >
                                      <v-select
                                        :class="[
                                          $v.formKm2.tif_origen_id.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid',
                                          $store.getters.getDarkMode
                                            ? 'dark-vselect'
                                            : '',
                                        ]"
                                        v-model="origen2"
                                        placeholder="Origen"
                                        label="nombre"
                                        :options="listasForms.tif_origen"
                                        @input="getSelectOrigen2()"
                                        class="form-control form-control-sm p-0"
                                      ></v-select>
                                    </span>
                                    <span v-else>
                                      {{ km.sitio_origen.nombre }}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      v-if="
                                        formActualizarKm &&
                                          idActualizarKm == index
                                      "
                                    >
                                      <v-select
                                        :class="[
                                          $v.formKm2.tif_destino_id.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid',
                                          $store.getters.getDarkMode
                                            ? 'dark-vselect'
                                            : '',
                                        ]"
                                        v-model="destino2"
                                        placeholder="Destino"
                                        label="nombre"
                                        :options="listasForms.tif_destino"
                                        @input="getSelectDestino2()"
                                        class="form-control form-control-sm p-0"
                                      ></v-select>
                                    </span>
                                    <span v-else>
                                      {{ km.sitio_destino.nombre }}
                                    </span>
                                  </td>
                                  <td>{{ km.bloque.nombre }}</td>
                                  <td>
                                    <span
                                      v-if="
                                        formActualizarKm &&
                                          idActualizarKm == index
                                      "
                                      ><input
                                        type="number"
                                        class="form-control form-control-sm"
                                        id="kilometraje"
                                        v-model="formKm2.kilometraje"
                                        :class="
                                          $v.formKm2.kilometraje.$invalid
                                            ? 'is-invalid'
                                            : 'is-valid'
                                        "
                                      />
                                    </span>
                                    <span v-else>
                                      {{ km.kilometraje }}
                                    </span>
                                  </td>

                                  <td>
                                    <span
                                      v-if="
                                        formActualizarKm &&
                                          idActualizarKm == index
                                      "
                                    >
                                      <button
                                        type="button"
                                        class="btn btn-sm bg-success"
                                        v-show="!$v.formKm2.$invalid"
                                        @click="guardarActualizacionKm(index)"
                                      >
                                        <i class="fas fa-save"></i>
                                      </button>
                                    </span>
                                    <span v-else>
                                      <!-- Botón para mostrar el formulario de actualizar -->
                                      <button
                                        type="button"
                                        class="btn btn-sm bg-navy"
                                        @click="verFormActualizarKm(index)"
                                        :disabled="
                                          !$store.getters.can(
                                            'tif.operaciones.edit'
                                          ) || act == 2
                                        "
                                      >
                                        <i class="fas fa-edit"></i>
                                      </button>
                                      <!-- Botón para borrar -->
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        @click="destroyKm(km.id)"
                                        v-if="
                                          $store.getters.can(
                                            'tif.operaciones.deleteKm'
                                          )
                                        "
                                        :disabled="
                                          !$store.getters.can(
                                            'tif.operaciones.edit'
                                          ) || act == 2
                                        "
                                      >
                                        <i class="fas fa-trash-alt"></i>
                                      </button>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <button
                      class="btn bg-primary col-md-1"
                      v-show="!$v.form.$invalid"
                      v-if="$store.getters.can('tif.operaciones.save')"
                      @click="valOperBloque()"
                    >
                      <i class="fas fa-paper-plane"></i><br />Guardar
                    </button>
                    <button class="btn bg-secondary col-md-1" @click="back()">
                      <i class="fas fa-reply"></i><br />Volver
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!---- Modal Cargos Productos ---->
    <div class="modal fade" id="modal-form-cargos">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Asignación Cargo</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="usuario_id">Usuario</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="usuario"
                    placeholder="Usuario"
                    label="name"
                    :options="listasForms.usuarios"
                    @input="getSelectUsuario()"
                    class="form-control form-control-sm p-0"
                  ></v-select>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="cargo">Cargos</label>
                <select
                  class="form-control form-control-sm"
                  v-model="formCa.cargo"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="cargo in listasForms.cargos"
                    :key="cargo.numeracion"
                    :value="cargo.numeracion"
                  >
                    {{ cargo.descripcion }}
                  </option>
                </select>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="orden">Orden</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="orden"
                    disabled="true"
                    v-model="formCa.orden"
                  />
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="estado">Estado</label>
                <select
                  class="form-control form-control-sm"
                  v-model="formCa.estado"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <button
                  type="button"
                  class="btn btn-primary"
                  style="margin-top: 25px"
                  v-show="formCa.cargo && formCa.estado && formCa.usuario_id"
                  @click="validarCargo()"
                  v-if="$store.getters.can('tif.operaciones.saveCargo')"
                >
                  <i class="fas fa-paper-plane"></i> Guardar Cargo
                </button>
              </div>
            </div>
            <div>
              <table
                class="
                  table table-bordered table-striped table-hover table-sm
                "
              >
                <thead class="thead-dark">
                  <tr>
                    <th class="text-center">Usuario</th>
                    <th class="text-center">Cargo</th>
                    <th class="text-center">Estado</th>
                    <th class="text-center">Orden</th>
                    <th class="text-center" style="width: 15%">
                      Accion
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(cargoO, index) in cargoOperacion"
                    :key="cargoO.id"
                  >
                    <td>
                      <span v-if="formActualizarCa && idActualizarCa == index"
                        ><v-select
                          v-model="usuario2"
                          placeholder="Usuario"
                          label="name"
                          :options="listasForms.usuarios"
                          @input="getSelectUsuario2()"
                          class="form-control form-control-sm p-0"
                        ></v-select
                      ></span>
                      <span v-else> {{ cargoO.user.name }} </span>
                    </td>
                    <td>
                      <span v-if="formActualizarCa && idActualizarCa == index">
                        <select
                          class="form-control form-control-sm"
                          v-model="formCa2.cargo"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="cargo in listasForms.cargos"
                            :key="cargo.numeracion"
                            :value="cargo.numeracion"
                          >
                            {{ cargo.descripcion }}
                          </option>
                        </select>
                      </span>
                      <span v-else> {{ cargoO.nCargo }} </span>
                    </td>
                    <td>
                      <span v-if="formActualizarCa && idActualizarCa == index">
                        <select
                          class="form-control form-control-sm"
                          v-model="formCa2.estado"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </span>
                      <span v-else> {{ cargoO.nEstado }} </span>
                    </td>
                    <td class="text-center">{{ cargoO.orden }}</td>
                    <td>
                      <span v-if="formActualizarCa && idActualizarCa == index">
                        <!-- Botón para mostrar el formulario de actualizar -->
                        <button
                          type="button"
                          class="btn btn-sm bg-success"
                          v-show="formCa2.usuario_id"
                          @click="guardarActualizacionCa(index)"
                        >
                          <i class="fas fa-save"></i></button
                      ></span>
                      <span v-else>
                        <button
                          type="button"
                          class="btn btn-sm bg-navy"
                          @click="verFormActualizarCa(index)"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                        <button
                          class="btn btn-sm btn-success"
                          v-if="
                            index + 1 != 1 &&
                              $store.getters.can('tif.operaciones.ordenarItems')
                          "
                          @click="subirOrden(index)"
                        >
                          <i class="fas fa-arrow-up"></i></button
                        ><button
                          class="btn btn-sm btn-warning"
                          v-if="
                            index + 1 != cargoOperacion.length &&
                              $store.getters.can('tif.operaciones.ordenarItems')
                          "
                          @click="bajarOrden(index)"
                        >
                          <i class="fas fa-arrow-down"></i>
                        </button>

                        <button
                          type="button"
                          class="btn btn-sm btn-danger"
                          v-on:click="
                            destroyCargo(
                              cargoO.id,
                              cargoO.producto_id,
                              cargoO.tif_operacion_id
                            )
                          "
                          v-if="
                            $store.getters.can('tif.operaciones.deleteCargo')
                          "
                        >
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";

export default {
  name: "TifOperacionesForm",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      formActualizarCa: false,
      idActualizarCa: null,
      producto: null,
      act: 1,
      accion: null,
      usuario: {},
      usuario2: {},
      origen: {},
      origen2: {},
      destino: {},
      destino2: {},
      cargoOperacion: [],
      kilometros: [],
      formActualizarKm: false,
      idActualizarKm: null,
      method: null,
      form: {
        bloque: null,
        descripcion: null,
        op_id: null,
      },
      operacion: null,
      listasForms: {
        bloques: [],
        productos: [],
        cargos: [],
        operaciones: [],
        tif_origen: [],
        tif_destino: [],
        pho: [],
        usuarios: [],
      },
      savep: {
        tif_operacion_id: null,
        producto_id: null,
      },
      operaciones: {
        operacio_sitio_id: null,
        tipo_operacion_id: null,
        sitio_id: null,
      },
      formCa: {
        tif_operacion_id: null,
        producto_id: null,
        usuario_id: null,
        cargo: null,
        orden: null,
        estado: null,
      },
      formCa2: {
        usuario_id: null,
        cargo: null,
        estado: null,
      },
      formKm: {
        tif_origen_id: null,
        tif_destino_id: null,
        kilometraje: null,
      },
      formKm2: {
        tif_origen_id: null,
        tif_destino_id: null,
        kilometraje: null,
      },
      validar: null,
    };
  },

  validations() {
    return {
      form: {
        descripcion: {
          required,
        },
        bloque: {
          required,
        },
      },
      formKm: {
        tif_origen_id: {
          required,
        },
        tif_destino_id: {
          required,
        },
        kilometraje: {
          required,
        },
      },
      formKm2: {
        tif_origen_id: {
          required,
        },
        tif_destino_id: {
          required,
        },
        kilometraje: {
          required,
        },
      },
    };
  },

  methods: {
    async init() {
      this.cargando = true;
      this.act = this.$route.params.act;
      this.accion = this.$route.params.accion;
      this.listasForms.pho = [];
      if (this.accion == "Editar") {
        this.method = "PUT";
        this.operacion = this.$route.params.data_edit;
        this.form.bloque = this.operacion.bloque;
        this.form.descripcion = this.operacion.descripcion;
        await this.getProductosHasOp();
        await this.getKm();
      } else {
        this.method = "POST";
      }
      this.cargando = false;
    },

    buscarBloques(search) {
      let me = this;
      me.listasForms.bloques = [];
      if (search != "") {
        var url = "api/admin/bloques/listaSearch?bloque=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.bloques = response.data;
          })
          .catch(function(e) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getOrigen() {
      axios.get("/api/tif/sitios/lista").then((response) => {
        this.listasForms.tif_origen = response.data;
      });
    },

    getSelectOrigen() {
      this.formKm.tif_origen_id = null;
      if (this.origen) {
        this.formKm.tif_origen_id = this.origen.id;
        this.valSitio();
      }
    },

    getSelectOrigen2() {
      this.formKm2.tif_origen_id = null;
      if (this.origen2) {
        this.formKm2.tif_origen_id = this.origen2.id;
        this.valSitio2();
      }
    },

    getDestino() {
      axios.get("/api/tif/sitios/lista").then((response) => {
        this.listasForms.tif_destino = response.data;
      });
    },

    getSelectDestino() {
      this.formKm.tif_destino_id = null;
      if (this.destino) {
        this.formKm.tif_destino_id = this.destino.id;
        this.valSitio();
      }
    },

    getSelectDestino2() {
      this.formKm2.tif_destino_id = null;
      if (this.destino2) {
        this.formKm2.tif_destino_id = this.destino2.id;
        this.valSitio2();
      }
    },

    getProductos() {
      axios({
        method: "GET",
        url: "/api/tif/operaciones/operationsProductos",
      }).then((response) => {
        this.listasForms.productos = response.data;
      });
    },

    getCargos() {
      axios.get("/api/lista/150").then((response) => {
        this.listasForms.cargos = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getOperaciones() {
      axios.get("/api/lista/120").then((response) => {
        this.listasForms.operaciones = response.data;
      });
    },

    async getUsuario() {
      await axios
        .get("/api/admin/usuarios/lista", {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then((response) => {
          this.listasForms.usuarios = response.data;
        });
    },

    getSelectUsuario() {
      this.formCa.usuario_id = null;
      if (this.usuario) {
        this.formCa.usuario_id = this.usuario.id;
      }
    },

    getSelectUsuario2() {
      this.formCa2.usuario_id = null;
      if (this.usuario2) {
        this.formCa2.usuario_id = this.usuario2.id;
      }
    },

    getSelectCargo2() {
      this.formCa2.cargo = null;
      if (this.cargo2) {
        this.formCa2.cargo = this.cargo2.id;
      }
    },

    async valOperBloque() {
      this.cargando = true;
      let params = {
        bloque_id: this.form.bloque.id,
        descripcion: this.form.descripcion,
      };
      await axios
        .get("/api/tif/operaciones/valOperBloque", {
          params,
        })
        .then(async (response) => {
          this.validar = response.data.msg;
          this.cargando = false;
          this.save();
        });
    },

    // Acciones de formularios
    async save() {
      this.cargando = true;
      if (this.validar == "si") {
        this.form.descripcion = null;
        await this.$swal({
          icon: "error",
          title:
            "La descripción digitada ya se encuentra creada para ese bloque.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        this.cargando = false;
      } else {
        if (!this.$v.form.$invalid) {
          // MOVIENDO LOS OBJETOS
          if (this.operacion) {
            this.form.op_id = this.operacion.id;
          }

          axios({
            method: this.method,
            url: "/api/tif/operaciones",
            data: this.form,
          })
            .then((response) => {
              //this.$route.params.operacion = response.data.id;
              this.accion = "Editar";
              this.operacion = response.data;
              this.form.bloque = this.operacion.bloque;
              this.form.descripcion = this.operacion.descripcion;
              this.$swal({
                icon: "success",
                title: "Se actualizó el operación correctamente.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            })
            .catch((e) => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: `Ha ocurrido un error: ${e.response.data.message}`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      }
    },

    back() {
      return this.$router.replace("/tif/operaciones");
    },

    validarOperacion() {
      this.cargando = true;
      axios({
        method: "GET",
        url: "/api/tif/operaciones/validarOperacion",
        params: [this.operacion.id, this.producto],
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        this.productosP();
      });
    },

    productosP() {
      this.cargando = true;
      if (this.validar == "si") {
        this.$swal({
          icon: "error",
          title: `Ya existe ese producto para esta operación`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.cargando = false;
      } else {
        this.savep.tif_operacion_id = this.operacion.id;
        this.savep.producto_id = this.producto;
        axios({
          method: "POST",
          url: "/api/tif/operaciones/producto",
          data: this.savep,
        })
          .then((response) => {
            this.$swal({
              icon: "success",
              title: "Se asigno la operacion correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getProductosHasOp();
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e.response.data.message}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async getProductosHasOp() {
      this.listasForms.pho = [];
      this.cargando = true;
      await axios({
        method: "GET",
        url: "/api/tif/operaciones/getProductosHasOp",
        params: this.operacion.id,
      }).then((response) => {
        this.listasForms.pho = response.data;
        this.cargando = false;
      });
    },

    abrirModal(item) {
      this.limpiarModal();
      this.getCargos();
      this.getEstados();
      this.getUsuario();
      this.producto = item.producto_id;
      this.getCargoProducto();
    },

    validarCargo() {
      this.cargando = true;
      axios({
        method: "GET",
        url: "/api/tif/operaciones/validarCargo",
        params: [this.operacion.id, this.formCa.usuario_id, this.producto],
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        this.saveCargo();
      });
    },

    saveCargo(ca = 0) {
      this.cargando = true;
      let datos = {};
      if (ca == 0) {
        datos = {
          tif_operacion_id: this.operacion.id,
          usuario_id: this.formCa.usuario_id,
          producto_id: this.producto,
          cargo: this.formCa.cargo,
          orden: this.formCa.orden,
          estado: this.formCa.estado,
        };
      } else {
        datos = {
          id: this.formCa2.id,
          usuario_id: this.formCa2.usuario_id,
          cargo: this.formCa2.cargo,
          estado: this.formCa2.estado,
          /*   tif_operacion_id: this.operacion.id,
          producto_id: this.producto, */
        };
      }
      if (this.validar == "si") {
        this.$swal({
          icon: "error",
          title: `Ya existe ese cargo para este producto`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.cargando = false;
      } else {
        axios({
          method: "POST",
          url: "/api/tif/operaciones/cargo",
          data: datos,
        })
          .then((response) => {
            this.getCargoProducto();
            this.limpiarModal();
            this.$swal({
              icon: "success",
              title: "Se asigno el cargo correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e.response.data.message}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    verFormActualizarCa(cargo_id) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos
      this.idActualizarCa = cargo_id;
      this.formCa2.id = this.cargoOperacion[cargo_id].id;
      this.usuario2 = this.cargoOperacion[cargo_id].user;
      this.formCa2.usuario_id = this.cargoOperacion[cargo_id].usuario_id;
      this.formCa2.cargo = this.cargoOperacion[cargo_id].cargo;
      this.formCa2.estado = this.cargoOperacion[cargo_id].estado;

      // Mostramos el formulario
      this.formActualizarCa = true;
    },

    guardarActualizacionCa(cargo_id) {
      if (!this.validarUsuarios(cargo_id)) {
        // Ocultamos nuestro formulario de actualizar
        this.formActualizarCa = false;

        // Actualizamos los datos
        this.cargoOperacion[cargo_id].id = this.formCa2.id;
        this.cargoOperacion[cargo_id].user = this.usuario2;
        this.cargoOperacion[cargo_id].cargo = this.formCa2.cargo;
        this.cargoOperacion[cargo_id].estado = this.formCa2.estado;
        this.saveCargo(1);
      } else {
        this.$swal({
          customClass: {
            container: "my-swal",
          },
          icon: "error",
          title: `El usuario ya se encuentra en la lista de cargos.`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    },

    getCargoProducto() {
      this.cargando = true;
      axios({
        method: "GET",
        url: "/api/tif/operaciones/getCargoProducto",
        params: [this.operacion.id, this.producto],
      }).then((response) => {
        this.cargoOperacion = response.data;
        this.formCa.orden = this.cargoOperacion.length + 1;
        this.cargando = false;
      });
    },

    destroyCargo(id, producto_id, tif_operacion_id) {
      this.$swal({
        title: "Esta seguro de eliminar este cargo de la operacion?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // <--
        if (result.value) {
          this.cargando = true;
          axios
            .delete(
              "/api/tif/operaciones/cargo/" +
                id +
                "/" +
                producto_id +
                "/" +
                tif_operacion_id
            )
            .then(() => {
              this.getCargoProducto();
              this.$swal({
                icon: "success",
                title: "Se elimino la seccion de manera exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    limpiarModal() {
      this.formCa = {
        tif_operacion_id: null,
        producto_id: null,
        cargo: null,
        usuario_id: null,
        estado: null,
      };
      this.usuario = {};
    },

    validarKm() {
      this.cargando = true;
      axios({
        method: "GET",
        url: "/api/tif/operaciones/validarKm",
        params: [
          this.operacion.bloque_id,
          this.formKm.tif_origen_id,
          this.formKm.tif_destino_id,
        ],
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        this.saveKm();
      });
    },

    valSitio() {
      if (this.formKm.tif_origen_id && this.formKm.tif_destino_id) {
        let origen = this.formKm.tif_origen_id;
        let destino = this.formKm.tif_destino_id;

        if (origen == destino) {
          this.formKm.tif_origen_id = null;
          this.formKm.tif_destino_id = null;
          this.origen = {};
          this.destino = {};
          this.$swal({
            icon: "error",
            title: `Los sitio no pueden ser iguales para la asignación del kilometraje.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validarKm2() {
      this.cargando = true;
      axios({
        method: "GET",
        url: "/api/tif/operaciones/validarKm",
        params: [
          this.operacion.bloque_id,
          this.formKm2.tif_origen_id,
          this.formKm2.tif_destino_id,
        ],
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        this.saveKm(1);
      });
    },

    valSitio2() {
      if (this.formKm2.tif_origen_id && this.formKm2.tif_destino_id) {
        let origen = this.formKm2.tif_origen_id;
        let destino = this.formKm2.tif_destino_id;

        if (origen == destino) {
          this.formKm2.tif_origen_id = null;
          this.formKm2.tif_destino_id = null;
          this.origen2 = {};
          this.destino2 = {};
          this.$swal({
            icon: "error",
            title: `Los sitio no pueden ser iguales para la asignación del kilometraje.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    saveKm(km = 0) {
      this.cargando = true;
      this.formKm.tif_bloque_id = this.operacion.bloque_id;
      let datos = {};
      if (km == 0) {
        datos = {
          tif_origen_id: this.formKm.tif_origen_id,
          tif_destino_id: this.formKm.tif_destino_id,
          tif_bloque_id: this.formKm.tif_bloque_id,
          kilometraje: this.formKm.kilometraje,
        };
      } else {
        datos = {
          id: this.formKm2.id,
          tif_origen_id: this.formKm2.tif_origen_id,
          tif_destino_id: this.formKm2.tif_destino_id,
          kilometraje: this.formKm2.kilometraje,
        };
      }
      if (this.validar == "si") {
        this.$swal({
          icon: "error",
          title: `Ya existe ese Km para esta operación`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.cargando = false;
      } else {
        axios({
          method: "POST",
          url: "/api/tif/operaciones/km",
          data: datos,
        })
          .then((response) => {
            this.getKm();
            this.$swal({
              icon: "success",
              title: "Se asigno el km correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e.response.data.message}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getKm() {
      this.cargando = true;
      axios({
        method: "GET",
        url: "/api/tif/operaciones/getKm",
        params: this.operacion.bloque_id,
      }).then((response) => {
        this.kilometros = response.data;
        this.cargando = false;
      });
    },

    verFormActualizarKm(km_id) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos
      this.idActualizarKm = km_id;
      this.formKm2.id = this.kilometros[km_id].id;
      this.origen2 = this.kilometros[km_id].sitio_origen;
      this.formKm2.tif_origen_id = this.kilometros[km_id].tif_origen_id;
      this.destino2 = this.kilometros[km_id].sitio_destino;
      this.formKm2.tif_destino_id = this.kilometros[km_id].tif_destino_id;
      this.formKm2.kilometraje = this.kilometros[km_id].kilometraje;
      // Mostramos el formulario
      this.formActualizarKm = true;
    },

    guardarActualizacionKm(km_id) {
      // Ocultamos nuestro formulario de actualizar
      this.formActualizarKm = false;
      // Actualizamos los datos
      this.kilometros[km_id].id = this.formKm2.id;
      this.kilometros[km_id].sitio_origen = this.origen2;
      this.kilometros[km_id].sitio_destino = this.destino2;
      this.kilometros[km_id].kilometraje = this.formKm2.kilometraje;
      this.validarKm2();
    },

    destroyKm(id) {
      this.$swal({
        title: "Esta seguro de eliminar este Km de la operacion?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // <--
        if (result.value) {
          this.cargando = true;
          axios.delete("/api/tif/operaciones/km/" + id).then(() => {
            this.getKm();
            this.$swal({
              icon: "success",
              title: "Se elimino la seccion de manera exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });

            this.cargando = false;
          });
        }
      });
    },

    destroy(item) {
      this.$swal({
        title: "Esta seguro de eliminar esta producto de la operacion?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // <--
        if (result.value) {
          this.cargando = true;
          axios
            .delete("/api/tif/operaciones/destroyProducto", {
              params: {
                producto_id: item.producto_id,
                tif_operacion_id: item.tif_operacion_id,
              },
            })
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Se elimino la seccion de manera exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              this.getProductosHasOp();
              this.cargando = false;
            });
        }
      });
    },

    async subirOrden(index) {
      let orden = index + 1;
      const item_actual = this.cargoOperacion[index];
      const item_anterior = this.cargoOperacion[index - 1];
      // Se ajusta el arreglo para actualizar el orden
      const params = { item_actual, item_anterior };
      await this.actualizaOrden(params);
      await this.getCargoProducto();
    },

    async bajarOrden(index) {
      let orden = index + 1;
      const item_actual = this.cargoOperacion[index];
      const item_siguiente = this.cargoOperacion[index + 1];
      // Se ajusta el arreglo para actualizar el orden
      const params = { item_actual, item_siguiente };
      await this.actualizaOrden(params);
      await this.getCargoProducto();
    },

    actualizaOrden(params) {
      axios({
        method: "PUT",
        url: "/api/tif/operaciones/ordenarItems",
        data: params,
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Se ordenó el Item de manera correcta",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    validarUsuarios(index) {
      return this.cargoOperacion.find(
        (e, i) =>
          e.usuario_id == this.formCa2.usuario_id &&
          e.cargo == this.formCa2.cargo &&
          i != index
      )
        ? true
        : false;
    },
  },

  async mounted() {
    await this.init();
    await this.getProductos();
    await this.getEstados();
    await this.getOperaciones();
    await this.getOrigen();
    await this.getDestino();
  },
};
</script>
<style>
.my-swal {
  z-index: 20000 !important;
}
</style>
